// **  Initial State
const initialState = {
  orders: [],
  errorTrack: false,
  payments: []
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ORDERS":
      return { ...state, orders: action.payload };
    case "FETCH_ORDERS_ERROR": {
      return { ...state, error: action.payload };
    }
    case "FETCH_DASH_CLIENT_SHIPMENTS": {
      return { ...state, shipments: action.payload };
    }
    case "DASHBOARD_DATA":
      return {
        ...state,
        dashData: action.response,
      };
    case "FETCH_LIMIT_TRANSACTIONS": {
      return { ...state, transactionsLimit: action.payload };
    }
    case "INIT_TRACK": {
      return { ...state, tracks: [], errorTrack: false };
    }
    case "FETCH_TRACK_ORDER": {
      return { ...state, tracks: action.payload };
    }
    case "FETCH_TRACK_ORDER_TAG": {
      return {
        ...state,
        tracks: action.payload?.tracks,
        order: action.payload?.order,
      };
    }
    case "FETCH_TRACK_ORDER_ERROR": {
      return { ...state, errorTrack: true };
    }
    case "FETCH_CUSTOMER_BY_NAME": {
      return { ...state, customer: action.payload };
    }
    case "INIT": {
      debugger;
      return { ...state, loadingCalc: true, cancelOrderSuccess: false };
    }
    case "LIST_PRICE_TERMS": {
      return {
        ...state,
        listOptions: action.payload.pricesAndTerms,
        errorPriceTerms: action.payload.erro,
        loadingCalc: false,
      };
    }
    case "PRINT_OUT": {
      debugger;
      return {
        ...state,
        loadingPrintOut: false,
        successPrintOut: true,
      };
    }
    case "PRINT_OUT_ERROR": {
      return {
        ...state,
        loadingPrintOut: false,
        successPrintOut: false,
        errorPrintOut: action.payload,
      };
    }
    case "PRINT_OUT_INIT": {
      debugger;
      return {
        ...state,
        loadingPrintOut: true,
        successPrintOut: false,
        errorPrintOut: false,
      };
    }
    case "CANCEL_ORDER_MOTTU": {
      return { ...state, cancelOrderSuccess: true };
    }
    case "GET_PAYMENTS_PRE":
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
};

export default ordersReducer;
