// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import orders from './orders'
import users from './users'
import transactions from './transactions'
import tags from './tags'
import customers from './customers'
import invoice from '@src/views/invoice/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  orders,
  transactions,
  users,
  tags,
  invoice,
  customers
})

export default rootReducer
