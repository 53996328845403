const initialState = {
  invoices: [],
  payments: [],
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_INVOICES":
      return {
        ...state,
        invoices: action.payload,
      };
    case "GET_PAYMENTS_PRE":
      return {
        ...state,
        payments: action.payload,
      };
    case "GET_INVOICE":
      return {
        ...state,
        invoice: action.payload,
      };
    case "GENERATE_PAYMENT":
      return {
        ...state,
        //invoice: action.payload,
      };
    default:
      return { ...state };
  }
};
export default invoiceReducer;
