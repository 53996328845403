// **  Initial State
const initialState = {
    users: []
  }
  
  const usersReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_USERS":
        return { ...state, users: action.payload }
      case "FETCH_USERS_ERROR": {
        return { ...state, error: action.payload }
      }
      case "CREATE_USER":
      const data = [...state.users]
      const user = action.data
      user.id = action.response.id
      data.push(user)
      return { ...state, users: data }
    case "UPDATE_USER":
      const dataUpdate = [...state.users]
      const indexUpdate = state.users.findIndex(
        (item) => item.id === action.response.id
      )
      dataUpdate[indexUpdate] = action.data
      return { ...state, users: dataUpdate }
    case "DELETE_INIT":
      return { ...state, deleteUserSuccess: false, deleteUserError: false }
    case "DELETE_USER":
      const index = state.users.findIndex((item) => item.id === action.data.id)
      const deleteData = [...state.users]
      deleteData.splice(index, 1)
      return { ...state, users: deleteData, deleteUserSuccess: true }
    case "DELETE_USER_ERROR":
      return { ...state, deleteUserError: true }
      default:
        return state
    }
  }
  
  export default usersReducer
  