// **  Initial State
const initialState = {
  transactions: []
}

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TRANSACTIONS":
      return { ...state, transactions: action.payload }
    case "CREATE_TRANSACTION":
      const data = [...state.transactions]
      const transaction = action.data
      transaction.id = action.response.id
      data.push(transaction)
      return { ...state, transactions: data }
    default:
      return state
  }
}

export default transactionsReducer
