// **  Initial State
const initialState = {
  customer:{},
  userData: {},
  error: false,
  success: false,
  user: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.payload }
    case 'LOGIN_ERROR':
      return { ...state, error: action.payload }
    case 'LOGOUT':
      return { ...state, userData: {} }
    case 'CHANGE_PASSWORD':
      return { ...state }
    case 'UPDATE_USER_INIT':
      return { ...state, success: false, error: false }
    case 'UPDATE_USER':
      return { ...state, success: true }
    case 'FETCH_USER':
      return { ...state, user: action.response }
    case 'UPDATE_USER_ERROR':
      return { ...state, error: true }
    case 'FETCH_TOKEN_FRENET':
      return { ...state, tokenFrenet:action.payload}
      case 'FETCH_CUSTOMER':
      return { ...state, customer:action.payload}
    case 'INIT_AUTH':
      return { ...state, forgot:false,reset:false}
    case 'FORGOT_PASSWORD':
      return { ...state, forgot:true}
    case 'RESET_PASSWORD':
      return { ...state, reset:true}
    default:
      return state
  }
}

export default authReducer
