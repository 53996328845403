// **  Initial State
const initialState = {
  customers: [],
  carriers: [],
  services: [],
  deleteUserSuccess: false,
  deleteUserError: false,
  customer: {},
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_CUSTOMER":
      return {
        ...state,
        addCustomer: false,
        updateSuccessCustomer: false,
        customerCreateError: false,
      };
    case "FETCH_CUSTOMER":
      return {
        ...state,
        customer: action.payload,
      };
    case "CREATE_CUSTOMER":
      return { ...state, addCustomer: true };
    case "CREATE_CUSTOMER_ERROR":
      console.log(action.response);
      return { ...state, customerCreateError: action.response?.errorMessage };
    case "UPDATE_CUSTOMER":
      return { ...state, updateSuccessCustomer: true };
    case "FETCH_CARRIERS":
      return { ...state, carriers: action.payload };
    case "FETCH_CUSTOMER_CARRIER_SERVICE":
      return { ...state, services: action.payload };
    case "CREATE_CUSTOMER_CARRIER_SERVICE":
      return { ...state, services: action.payload };

    default:
      return state;
  }
};

export default customersReducer;
