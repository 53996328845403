// **  Initial State
const initialState = {
  tags: [],
  listOptions: [],
  tagsResponse: null,
};

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TAGS":
      return { ...state, tags: action.payload };
    case "FETCH_TAGS_ERROR": {
      return { ...state, error: action.payload };
    }
    // case "PRINT_OUT": {
    //   const dataTag = [...state.tags];
    //   const newTag = action.data;
    //   newTag.id = action.payload.info.id;
    //   newTag.carrier = action.payload.info.carrierInfo;
    //   newTag.carrierService = action.payload.info.carrierServiceInfo;
    //   dataTag.push(newTag);
    //   return {
    //     ...state,
    //     loadingPrintOut: false,
    //     tagsResponse: action.payload,
    //     tags: dataTag,
    //   };
    // }
    // case "PRINT_OUT_INIT": {
    //   return { ...state, loadingPrintOut: true };
    // }
    case "CREATE_TAG":
      const data = [...state.tags];
      const tag = action.data;
      tag.id = action.response.id;
      data.push(tag);
      return { ...state, tags: data };
    case "UPDATE_TAG":
      const dataUpdate = [...state.tags];
      const indexUpdate = state.tags.findIndex(
        (item) => item.id === action.response.id
      );
      dataUpdate[indexUpdate] = action.data;
      return { ...state, tags: dataUpdate };
    case "DELETE_INIT":
      return { ...state, deleteUserSuccess: false, deleteUserError: false };
    case "DELETE_TAG":
      const index = state.tags.findIndex((item) => item.id === action.data.id);
      const deleteData = [...state.tags];
      deleteData.splice(index, 1);
      return { ...state, tags: deleteData, deleteUserSuccess: true };
    case "DELETE_TAG_ERROR":
      return { ...state, deleteUserError: true };
    default:
      return state;
  }
};

export default tagsReducer;
